@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
button, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  outline: none;
  border: none; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

*:focus {
  outline: none; }

a {
  text-decoration: none; }

nav {
  list-style: none; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

body {
  min-width: 1050px;
  overflow-x: hidden; }

.header {
  overflow-x: hidden; }

.menu-top {
  display: flex;
  box-sizing: border-box; }
  .menu-top__item {
    text-align: center;
    margin: 5px 3vw 5px 0; }
  .menu-top__phone {
    margin-left: auto;
    display: flex;
    align-items: center; }
    .menu-top__phone-text {
      margin-left: 0.4vw; }
    .menu-top__phone-icon {
      margin-bottom: 3px; }

.menu-middle {
  display: flex;
  align-items: center;
  width: 100vw;
  padding: 37px 0 39px;
  box-sizing: border-box;
  justify-content: space-between; }
  .menu-middle__login {
    margin: 4px 0 0 0; }
    .menu-middle__login-text {
      margin-top: 1px;
      padding-left: 0.8vw;
      text-align: end;
      display: block; }
  .menu-middle__search {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 35vw; }

.menu-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: absolute; }
  .menu-bottom__item {
    white-space: nowrap;
    text-align: center;
    justify-content: center;
    padding: 14px 27px;
    margin: 0 3px; }
    .menu-bottom__item:first-child {
      margin-left: 0;
      padding-left: 0; }
  .menu-bottom__icon {
    margin-left: 0.5vw; }

.carousel-content__title {
  margin-top: 0;
  padding: 140px calc(100vw/9) 19px; }

.carousel-content__text {
  padding: 30px calc(100vw/9) 75px; }

.carousel-content__button {
  margin: 50px calc(100vw/9) 30px;
  padding: 0 24px; }

.carousel-content__slider-icon {
  margin: 88px calc(100vw/9) 0px;
  padding-bottom: 34px; }

.carousel-content__slider-button_left {
  margin-right: 1vw; }

.carousel-list {
  display: flex;
  flex-direction: row;
  margin-top: 0;
  padding: 25px 0 0;
  list-style-type: none;
  justify-content: space-between; }
  .carousel-list__item {
    display: flex;
    flex-direction: column; }
    .carousel-list__item-text {
      margin: 30px 0 65px; }

.destributers {
  margin: 115px 0 30px; }
  .destributers__title {
    margin-right: 3.7vw;
    margin-bottom: 23px;
    padding-bottom: 7px; }

.manufacturer {
  margin: 115px 0 0; }
  .manufacturer-link__icon {
    margin-left: 1vw; }
  .manufacturer-brand-grid {
    margin-top: 1.6vw; }
  .manufacturer__title {
    padding-bottom: 7px;
    margin-right: 4.2vw;
    margin-bottom: 24px; }

.brand-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 3vw;
  margin-top: 40px;
  grid-row-gap: 80px; }

.main-card {
  display: flex;
  flex-direction: row;
  margin: 120px 0;
  gap: 13px;
  width: 100%; }
  .main-card-left {
    display: flex;
    flex-direction: column;
    padding: 110px 19.5vw 115px 11vw;
    width: 49%; }
    .main-card-left__svg {
      margin: 30px 0; }
    .main-card-left__content {
      margin: 50px 0 83px; }
  .main-card-right {
    display: flex;
    flex-direction: column;
    padding: 110px 23vw 115px 8vw;
    width: 49%; }
    .main-card-right__content {
      margin: 50px 0; }
    .main-card-right__svg {
      margin: 30px 0; }
  .main-card__link {
    margin: 20px 0; }
    .main-card__link-svg {
      margin-bottom: -0.1vw;
      margin-left: 0.6vw; }

.main-title {
  margin: 143px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 20px; }

.login-main {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(10, 9.3%);
  grid-column-gap: 13px;
  grid-row-gap: 0.6vw;
  margin: 82px 0 2vh; }
  .login-main-title {
    margin-bottom: 52px;
    padding-bottom: 11px;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    min-width: 16vw; }
  .login-main__button {
    padding: 0 25px; }
    .login-main__button-left {
      margin-left: auto;
      margin-top: auto; }

.login-card__title {
  margin: 18px 0; }

.login-card__label {
  margin-top: 25px; }
  .login-card__label-email {
    margin-top: 0;
    width: 100%; }
  .login-card__label-phone {
    margin-right: 1.5vw;
    width: 45%; }
  .login-card__label-name {
    margin: 25px 0 20px;
    width: 45%; }

.login-card-left {
  display: flex;
  flex-direction: column;
  padding: 30px 5vw 45px;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 6; }
  .login-card-left__input {
    margin-top: 0.5vw; }
  .login-card-left__link {
    margin-top: 20px; }
  .login-card-left__label_first {
    margin-top: 50px; }

.login-card__input {
  padding-left: 15px;
  margin-left: -0.3vw; }

.login-card__label {
  margin-left: 0.3vw; }
  .login-card__label-right {
    margin-top: 9px; }

.login-card-right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 5vw;
  grid-row-start: 2;
  grid-column-start: 6;
  grid-column-end: 11; }
  .login-card-right__input {
    margin-top: 10px; }
  .login-card-right__button {
    margin: 15px 0 15px auto; }
  .login-card-right__content {
    margin-bottom: 25px; }
  .login-card-right__text {
    margin-top: 5px; }

.change-password__main {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  grid-column-gap: 0.4vw;
  grid-row-gap: 0.6vw;
  width: 23vw;
  padding: 40px 4vw; }

.change-password__title {
  margin: 20px 0; }

.change-password__input {
  margin: 10px 0 10px -0.3vw;
  padding-left: 15px; }

.change-password__label {
  margin-left: 0.3vw; }

.change-password__button {
  width: 8vw;
  margin: 45px 0vw 10px 15vw;
  min-width: 146px; }

.footer {
  padding: 0; }
  .footer-menu {
    display: grid;
    grid-template-columns: repeat(2, 11.2vw);
    grid-row-gap: 37px;
    padding-left: 0;
    margin: 70px 0 68px; }
  .footer-navigation {
    display: flex;
    flex-direction: row;
    gap: 6vw;
    padding: 19px 0 25px; }

.alert {
  margin-left: 0.3vw; }
  .alert__login {
    position: absolute;
    margin-bottom: 30px;
    align-self: end; }
    .alert__login-input {
      max-width: 14vw;
      display: block;
      margin-top: 7px; }
  .alert__product {
    margin-bottom: 10px; }

.categories__popup {
  position: absolute;
  top: 45px;
  left: 9.7vw;
  display: flex;
  flex-direction: row;
  z-index: 5; }
  .categories__popup-title {
    margin: 15px 1.5vw;
    width: 10vw; }
  .categories__popup-list {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    margin: 4px 0;
    max-height: calc(100vh - 202px); }
  .categories__popup-item {
    padding: 10px 1.5vw;
    width: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .categories__popup-item-icon {
      width: 10px;
      height: 10px;
      margin: 0;
      align-self: center; }
  .categories__popup-error {
    padding: 40px 1.5vw; }

.categories-page {
  display: flex;
  flex-direction: column;
  padding: 0; }
  .categories-page__skeleton {
    padding: 20px 0; }
  .categories-page__title {
    margin: 40px 0; }
    .categories-page__title-content {
      margin-left: 1vw; }
  .categories-page__list {
    margin: 0 0 31px; }
    .categories-page__list-title {
      margin-bottom: 9px; }
  .categories-page__item {
    padding: 9px 4vw 0 0;
    display: block; }
    .categories-page__item-title {
      margin-right: 1vw; }

.categories-item {
  padding: 0 0 10px; }
  .categories-item_display {
    display: flex; }
  .categories-item__title {
    margin: 40px 0; }
  .categories-item__table {
    display: block; }
    .categories-item__table_display {
      display: table; }
    .categories-item__table-item {
      padding: 10px 0; }
      .categories-item__table-item-photo {
        display: block;
        padding: 0 10px; }
      .categories-item__table-item-img {
        height: auto; }
    .categories-item__table-wrapper {
      width: 100%; }
    .categories-item__table-empty {
      display: flex;
      min-height: calc(100vh - 479px);
      width: 100%;
      justify-content: center;
      align-items: center; }
    .categories-item__table-partnumber {
      white-space: nowrap;
      padding: 5px 10px;
      max-width: 100%;
      flex-grow: 1; }
  .categories-item__skeleton {
    margin: -10px -30px 0;
    border-spacing: 30px;
    border-collapse: separate;
    width: 104%; }
    .categories-item__skeleton-item {
      padding: 20px; }
      .categories-item__skeleton-item:first-child {
        width: 15vw; }
      .categories-item__skeleton-item:nth-child(2n) {
        width: 12vw; }
      .categories-item__skeleton-item:nth-child(3n) {
        width: 5vw; }
    .categories-item__skeleton-header {
      border-spacing: 0px;
      border-collapse: collapse;
      padding: 20px; }
  .categories-item__filters {
    display: flex;
    text-align: center;
    overflow-x: scroll; }
    .categories-item__filters-item {
      display: flex;
      flex-direction: column;
      padding: 10px 0.5vw;
      justify-content: space-between; }
    .categories-item__filters-list {
      display: flex;
      flex-direction: column;
      height: 200px;
      overflow-x: hidden;
      margin-bottom: 10px; }
      .categories-item__filters-list-item {
        margin: 0 5px 10px;
        padding: 10px 0;
        width: 100%;
        min-width: max-content; }
        .categories-item__filters-list-item:first-child {
          margin-left: 0; }
    .categories-item__filters-title {
      margin: 5px 0;
      padding: 0 15px 10px; }
    .categories-item__filters-button {
      margin: 15px 0 15px auto;
      width: max-content;
      display: none; }

.navigation-block {
  display: flex;
  flex-direction: row;
  padding: 30px 0 0;
  align-items: center;
  margin-top: 45px; }

.navigation-group {
  padding: 0 1vw; }

.navigation-goback {
  padding-left: 0; }

.navigation-icon {
  margin-right: 0.5vw; }
  .navigation-icon__separate {
    margin: 0 1vw; }

.pagination {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0 10px auto; }
  .pagination__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto; }
  .pagination__number {
    padding: 5px 0.5vw; }
  .pagination__list {
    display: flex;
    flex-direction: row; }
  .pagination__item {
    margin-right: 1vw; }
  .pagination__nav {
    display: flex; }
  .pagination__select {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto; }
    .pagination__select-item {
      margin-left: 1vw;
      padding: 7px 0; }

.product-table {
  width: 100%; }
  .product-table__block {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    top: -40px; }
  .product-table__item {
    padding: 10px 1vw; }
    .product-table__item_checkbox {
      vertical-align: middle;
      text-align: end; }
  .product-table__title {
    margin-bottom: 8px; }
  .product-table__subtitle {
    width: max-content;
    margin: -10px 0 10px auto; }
    .product-table__subtitle_bottom {
      margin: 12px 1vw 5px auto; }
  .product-table__icon {
    margin-left: 1.2vw; }
  .product-table__checkbox {
    margin-right: 1vw; }
  .product-table__attributes {
    table-layout: fixed; }
  .product-table__list {
    margin: 5px 0; }

.product-cart {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 2vw 55px;
  width: 21%;
  margin: 40px calc(100vw/9) 0 1vw;
  position: absolute;
  overflow-y: scroll;
  max-height: 240px; }
  .product-cart__input {
    width: 5vw;
    height: 40px;
    padding: 2px 10px;
    text-align: center;
    margin-bottom: 20px;
    -moz-appearance: textfield; }
    .product-cart__input::-webkit-outer-spin-button {
      -webkit-appearance: none; }
    .product-cart__input::-webkit-inner-spin-button {
      -webkit-appearance: none; }
  .product-cart_empty {
    height: 100px;
    overflow: hidden; }
  .product-cart__info {
    margin-top: 5px; }
  .product-cart__title-item {
    margin-bottom: 20px; }
  .product-cart__description {
    font-size: 11px; }
  .product-cart__button {
    justify-content: center; }
    .product-cart__button-empty-content {
      width: 80% !important;
      justify-content: center;
      margin: auto; }
  .product-cart__price {
    position: relative;
    margin-left: auto; }
    .product-cart__price-item {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      justify-content: space-between; }
  .product-cart__icon {
    width: 30px;
    height: 30px;
    margin-left: 2vw; }

.product-popup {
  flex-direction: column;
  display: flex;
  position: fixed;
  overflow-y: auto;
  max-height: 500px;
  right: 10.5vw;
  left: 9vw;
  padding: 50px 65px;
  top: 50%;
  transform: translate(0, -50%) !important; }
  .product-popup__button {
    margin: 15px 0 10px;
    padding: 1em 1.6em; }
  .product-popup__input {
    padding: 0 10px;
    margin: 10px; }
  .product-popup__error {
    width: max-content;
    position: relative;
    left: 220px;
    top: 42px; }
  .product-popup_small {
    width: max-content;
    margin: auto; }
    .product-popup_small .product-orders__filter-buttons {
      margin: auto; }

.product-orders__table {
  margin: 0;
  width: 100%; }
  .product-orders__table-icon {
    margin-left: 0.3vw; }
  .product-orders__table-filter {
    margin: 0 auto; }
  .product-orders__table-button-wrapper {
    width: calc(100%/5); }

.product-orders__filter {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center; }
  .product-orders__filter-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
  .product-orders__filter-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
  .product-orders__filter-input {
    width: 6vw;
    padding: 15px 0 15px 3vw;
    margin-right: 2vw;
    min-width: 100px; }
  .product-orders__filter-from {
    position: relative; }
    .product-orders__filter-from::before {
      content: "c";
      position: absolute;
      left: 1.5vw;
      margin-top: 15px; }
  .product-orders__filter-to {
    position: relative; }
    .product-orders__filter-to::before {
      content: "по";
      position: absolute;
      left: 1vw;
      margin-top: 15px; }
  .product-orders__filter-label {
    margin: 15px 0 10px;
    width: 22vw; }
  .product-orders__filter-search {
    margin: 0;
    width: 100% !important; }
  .product-orders__filter-close {
    position: absolute;
    top: -10px;
    right: 0px;
    z-index: 150; }

.product-orders__dropdown {
  position: absolute;
  top: 55px;
  width: 100%; }
  .product-orders__dropdown-list {
    display: flex;
    flex-direction: column; }
  .product-orders__dropdown-item {
    padding: 10px 1vw;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.product-item {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
  height: max-content;
  padding: 0; }
  .product-item__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .product-item__wrapper-manufacturer {
      position: relative;
      margin: 20px 0 0px; }
  .product-item__info {
    display: flex;
    flex-direction: column;
    width: 20vw; }
    .product-item__info-title {
      margin: 20px 0 12px; }
      .product-item__info-title:first-child {
        margin-top: 0; }
  .product-item__photo {
    display: flex;
    flex-direction: column; }
    .product-item__photo-link {
      margin-top: 20px;
      max-width: 220px; }
  .product-item__title {
    margin: 40px 0;
    width: 94%; }
  .product-item__button {
    margin-bottom: 15px; }
  .product-item__manufacturer-image {
    position: absolute;
    right: 0;
    top: 0; }
  .product-item__manufacturer-name {
    max-width: calc(100% - 5vw); }
  .product-item__skeleton {
    margin-right: 1vw;
    margin-bottom: 40px; }
    .product-item__skeleton-page {
      margin-right: 10px;
      margin-top: 45px; }
    .product-item__skeleton-info {
      width: 37.6%;
      position: relative;
      top: 50px;
      left: 15px; }

.product-price__block {
  display: flex;
  align-items: flex-start;
  margin: 50px 0; }

.product-price__table {
  height: max-content;
  position: relative;
  min-height: 150px; }
  .product-price__table-item {
    text-align: center;
    vertical-align: middle;
    height: 40px; }
    .product-price__table-item_height {
      height: 60px;
      width: 10vw;
      text-align: end; }
    .product-price__table-item_error {
      width: max-content;
      margin-left: auto;
      position: relative; }
  .product-price__table-packer {
    margin-top: 10px; }
  .product-price__table-list {
    display: flex;
    justify-content: space-between;
    padding: 2px; }
  .product-price__table-button {
    padding: 12px 12px 12px 8px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center; }
    .product-price__table-button_left {
      padding: 12px 8px 12px 12px; }
  .product-price__table-quantity {
    padding: 0;
    width: 100%; }
  .product-price__table-title {
    margin: 5px 0; }
  .product-price__table-error {
    position: absolute;
    padding: 4px 12px;
    width: 100%;
    margin-top: 45px;
    box-sizing: border-box; }
  .product-price__table .loader__wrapper {
    height: 155px;
    position: absolute; }
  .product-price__table-block {
    display: flex;
    width: 90%;
    float: right;
    height: 40px;
    min-width: 110px; }

.product-price__cart {
  min-width: 13vw;
  height: max-content;
  margin-left: 0.7vw;
  box-sizing: border-box;
  padding: 7px 1.3vw;
  position: sticky;
  top: calc(50% - 102px); }
  .product-price__cart-total {
    display: flex;
    padding: 26px 0 0;
    justify-content: space-between;
    width: 100%; }
  .product-price__cart-price {
    width: 10vw;
    margin-top: 16px;
    text-align: end; }
  .product-price__cart-quantity {
    margin: 0;
    float: right;
    width: 100%;
    text-align: end;
    max-height: 44px; }
  .product-price__cart-block {
    display: flex;
    justify-content: right; }
  .product-price__cart-button {
    margin: 15px auto;
    width: 100%;
    justify-content: center;
    max-height: 44px; }
  .product-price__cart-icon {
    margin-left: 0.5vw; }
  .product-price__cart-default {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding-bottom: 20px; }
  .product-price__cart-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0; }
  .product-price__cart-count {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-end; }
  .product-price__cart-product {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0; }
    .product-price__cart-product :first-child {
      align-items: flex-start; }

.product-price__empty {
  width: 100%; }

.product__wrapper .container {
  padding-left: 0;
  padding-right: 0; }

.search-options__list {
  position: absolute;
  padding: 0px 0px 20px;
  top: 116px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 35vw;
  z-index: 100;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; }
  .search-options__list-item--show-all {
    padding: 10px 20px;
    padding-bottom: 0px; }

.search-options__item {
  padding: 10px 20px;
  height: 32px;
  display: flex;
  align-items: center;
  transition: 0.15s ease;
  gap: 10px; }

.search-options__key {
  margin-left: auto;
  max-width: 50%;
  text-align: right; }

.search-options__show-all-button {
  width: 100%;
  padding: 10px;
  border: solid 1px #b4babd;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease; }
  .search-options__show-all-button:hover {
    background-color: #f3f4f6; }

.table__item-product {
  position: relative;
  margin-top: 5px; }

.table__item-image {
  position: absolute;
  left: 10px; }

.table__item-description {
  margin-left: 2.5vw; }

.orders__table {
  width: 100%;
  margin-top: 14px; }

.container {
  padding-right: calc(100vw/9);
  padding-left: calc(100vw/9);
  width: 100vw;
  box-sizing: border-box; }

.loader__wrapper {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.loader__text {
  margin-left: 30px; }

.user-agreement__article {
  margin: 45px 0 10px;
  display: flex;
  flex-direction: column; }

.user-agreement__title-h1 {
  padding: 30px 0; }

.user-agreement__text {
  margin-bottom: 20px; }

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 4px 10px black;
  z-index: 1000;
  border-radius: 10px;
  width: 300px;
  text-align: center; }
  .popup__submit {
    padding: 8px 15px;
    border-radius: 5px;
    width: 100%;
    margin-top: 15px;
    background: #0f8cc8;
    color: #fff;
    cursor: pointer; }
  .popup__error {
    background: #ff000014;
    border-radius: 5px;
    padding: 10px;
    color: red;
    text-align: center !important;
    margin-top: 15px !important; }
  .popup__content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .popup__form-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px; }
  .popup__form p {
    text-align: left;
    margin: 5px 0; }
  .popup__form-input {
    background: #efefef;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 7px;
    margin-bottom: 5px; }
  .popup__close {
    margin-top: 5px;
    background: #ff4d4d;
    color: #fff;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 5px;
    transition: background .3s ease-in-out;
    width: 100%; }
    .popup__close:hover {
      background: #cc0000; }

@font-face {
  font-family: Monserrat;
  src: url("../fonts/montserrat-regular-webfont.woff2"), url("../fonts/montserrat-regular-webfont.woff"); }

@font-face {
  font-family: Monserrat_medium;
  src: url("../fonts/montserrat-medium-webfont.woff2"), url("../fonts/montserrat-medium-webfont.woff2"); }

@font-face {
  font-family: Monserrat_semibold;
  src: url("../fonts/montserrat-semibold-webfont.woff2"), url("../fonts/montserrat-semibold-webfont.woff"); }

@font-face {
  font-family: Monserrat_bold;
  src: url("../fonts/montserrat-bold-webfont.woff2"), url("../fonts/montserrat-bold-webfont.woff"); }

@font-face {
  font-family: Roboto_regular;
  src: url("../fonts/roboto-regular.woff2"), url("../fonts/Roboto-Regular-webfont.woff"); }

@font-face {
  font-family: Roboto_bold;
  src: url("../fonts/roboto-bold.woff2"), url("../fonts/roboto-bold.woff"); }

body {
  font-family: 'Monserrat', sans-serif; }

a {
  color: black; }

strong {
  font-family: 'Monserrat_bold', sans-serif; }

.categories-item__table-item b {
  font-family: 'Monserrat_bold', sans-serif; }

.search-history__title {
  margin-bottom: 8px;
  padding: 20px 20px 3px;
  line-height: 1.42;
  color: #9d9d9f;
  border-bottom: 1px solid #ececed;
  font-size: 14px; }

.search-history__item {
  padding: 3px 20px;
  cursor: pointer; }
  .search-history__item:hover {
    color: #0f8cc8;
    background: #f3f4f6; }

.button {
  background-color: #0f8cc8;
  color: white;
  border-radius: 5px;
  height: 50px;
  cursor: pointer;
  padding: 0 30px;
  display: flex;
  align-items: center; }
  .button__icon {
    width: 15px;
    height: 14px;
    fill: white;
    margin-left: 0.5vw; }
  .button__text {
    font-size: 1.2em; }
  .button_outline {
    border: 1px solid #0f8cc8;
    color: #0f8cc8;
    font-family: 'Monserrat_semibold', sans-serif;
    background-color: white; }
    .button_outline:hover {
      background-color: white; }
  .button:hover {
    background-color: #74bde0; }
  .button:active {
    background-color: #1a5977; }
  .button_disabled {
    color: #d2d2d2;
    background-color: #eeeeee; }

.menu-top {
  background-color: #171717; }
  .menu-top__phone {
    color: white;
    white-space: nowrap; }
    .menu-top__phone-icon {
      width: 12px;
      height: 12px;
      fill: white; }
    .menu-top__phone-text {
      font-size: 0.867em; }
  .menu-top__item {
    min-height: 27px;
    display: flex;
    color: #b6b6b6;
    max-width: max-content;
    align-items: center;
    font-size: 13px;
    font-family: 'Monserrat_medium', sans-serif; }
    .menu-top__item:hover {
      color: white; }
    .menu-top__item_active {
      color: #0f8cc8; }

.menu-middle__logo {
  max-width: 13.6vw;
  width: 14vw; }
  .menu-middle__logo .d {
    fill: #171717; }
  .menu-middle__logo .e {
    fill: #0f8cc8; }

.menu-middle__login {
  display: flex;
  flex-direction: row; }
  .menu-middle__login-icon {
    width: 25px;
    height: 25px; }
  .menu-middle__login-text {
    font-size: 0.9em;
    font-family: 'Monserrat_medium', sans-serif;
    color: #4b4b4b; }

.menu-middle__search-input {
  border: 1px solid #b6b6b6;
  border-radius: 30px;
  padding: 0 25px;
  height: 42px;
  width: 35vw;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Monserrat_medium', sans-serif; }
  .menu-middle__search-input::placeholder {
    color: #b6b6b6;
    font-size: 14px;
    font-family: 'Monserrat_medium', sans-serif; }
  .menu-middle__search-input_active {
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; }

.menu-middle__search-icon {
  font-size: 20px;
  fill: #b6b6b6;
  position: absolute;
  right: 1.5vw;
  top: 11px;
  width: 23px;
  height: 23px; }

.menu-bottom {
  background-color: #29272a; }
  .menu-bottom_sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding-top: 0px;
    padding-bottom: 0px; }
  .menu-bottom__item {
    min-height: 17px;
    display: flex;
    color: #b6b6b6;
    width: auto;
    align-items: center;
    font-size: 14px; }
    .menu-bottom__item:hover {
      color: white; }
      .menu-bottom__item:hover .menu-bottom__icon {
        fill: white;
        transform: rotate(270deg); }
      .menu-bottom__item:hover .menu-bottom__icon_rotate {
        fill: white;
        transform: rotate(90deg); }
    .menu-bottom__item_active {
      color: #0f8cc8; }
  .menu-bottom__icon {
    transform: rotate(90deg);
    width: 12px;
    height: 12px;
    fill: #b6b6b6; }
    .menu-bottom__icon_active {
      fill: #0f8cc8; }

.link {
  color: #b6b6b6;
  cursor: pointer; }
  .link__icon {
    width: 17px;
    height: 17px;
    fill: #b6b6b6;
    margin: 0 20px -4px 4px; }

.slider {
  color: white; }

.carousel-content {
  width: 100%;
  background-size: cover; }
  .carousel-content__img {
    position: absolute;
    width: 100%;
    height: 691px;
    z-index: -1;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    background: no-repeat;
    object-fit: cover; }
    .carousel-content__img_active {
      opacity: 1;
      display: block; }
  .carousel-content__title {
    font-size: 60px;
    width: 400px;
    font-family: "Monserrat_bold", sans-serif;
    line-height: 1.13;
    animation: carouselContentAnimation .6s .15s linear; }
  .carousel-content__text {
    font-size: 22px;
    width: 300px;
    animation: carouselContentAnimation .6s .15s linear; }
  .carousel-content__button {
    font-size: 14px;
    animation: carouselContentAnimation .6s .15s linear;
    border-radius: 30px; }
  .carousel-content__slider-icon {
    width: 60px;
    cursor: pointer;
    opacity: 1;
    animation: sliderButtonAnimation 1.2s .15s linear; }
  .carousel-content__slider-button {
    width: 14px;
    height: 22px;
    fill: white; }
    .carousel-content__slider-button_left {
      rotate: 180deg; }

.carousel-list {
  background: #171717;
  font-size: 30px;
  font-family: "Monserrat_medium", sans-serif; }
  .carousel-list__item {
    animation: carouselListAnimation .6s .3s linear; }
    .carousel-list__item:hover .carousel-list__item-number {
      font-family: "Monserrat_bold", sans-serif; }
    .carousel-list__item:hover .carousel-list__item-line {
      stroke-width: 20px;
      stroke: #0f8cc8; }
    .carousel-list__item-line {
      stroke-width: 20px;
      stroke: #29272a;
      height: 45px;
      width: 11.7vw; }
      .carousel-list__item-line_active {
        stroke-width: 20px;
        stroke: #0f8cc8; }
    .carousel-list__item-text {
      width: 9.1vw;
      font-size: 22px;
      line-height: 1.2;
      white-space: pre-wrap; }

.destributers__title {
  font-size: 27px;
  font-family: "Monserrat_bold", sans-serif;
  border-bottom: 3px solid black;
  display: inline-block;
  letter-spacing: 0.025em; }

.brand-grid__img {
  width: 13vw;
  height: 85px;
  object-fit: contain; }

.manufacturer__title {
  font-size: 27px;
  font-family: "Monserrat_bold", sans-serif;
  border-bottom: 3px solid black;
  display: inline-block;
  letter-spacing: 0.025em; }

.main-card-left {
  background: #d9e3e8;
  color: black; }
  .main-card-left__svg {
    background-image: url("/pictures/sponsored_logo_1.png");
    width: 200px;
    height: 50px;
    background-size: cover;
    animation: cardLeftAnimation .6s linear; }
  .main-card-left__content {
    font-size: 24px;
    width: 20vw;
    line-height: 1.35;
    font-family: 'Monserrat_bold', sans-serif;
    animation: cardLeftAnimation .6s linear; }

.main-card-right {
  background: black;
  color: white; }
  .main-card-right__svg {
    width: 220px;
    height: 50px;
    background-image: url("/pictures/sponsored_logo_2.png");
    background-size: cover;
    animation: cardRightAnimation .6s linear; }
  .main-card-right__content {
    font-size: 24px;
    line-height: 1.35;
    font-family: 'Monserrat_bold', sans-serif;
    animation: cardRightAnimation .6s linear; }

.main-card__link {
  color: #0f8cc8;
  font-size: 16px;
  font-family: 'Monserrat_medium', sans-serif;
  cursor: pointer; }
  .main-card__link-svg {
    fill: #0f8cc8;
    width: 15px;
    height: 15px; }
  .main-card__link-left {
    animation: cardLeftAnimation .6s linear; }
  .main-card__link-right {
    animation: cardRightAnimation .6s linear; }

.main-title {
  background-color: #0f8cc8;
  color: white;
  font-size: 13px; }
  .main-title__text {
    font-size: 28px;
    font-family: 'Monserrat_medium', sans-serif; }
  .main-title__button {
    background-color: #0f8cc8;
    border: 2px solid white;
    border-radius: 30px; }
    .main-title__button:hover {
      background-color: white;
      border: none;
      color: #0f8cc8; }
      .main-title__button:hover .button__icon {
        fill: #0f8cc8; }
    .main-title__button:active {
      background-color: white;
      border: none;
      color: #0f8cc8;
      box-shadow: 0 0 0 3px #0f8cc8;
      outline: #0f8cc8 solid 3px !important; }
      .main-title__button:active .button__icon {
        fill: #0f8cc8; }

.login-card__title {
  font-size: 18px;
  font-family: 'Monserrat_semibold', sans-serif; }

.login-card__label {
  display: block;
  font-size: 13px;
  font-family: 'Monserrat_medium', sans-serif;
  color: black; }
  .login-card__label-email {
    color: white; }
  .login-card__label-phone {
    color: white; }
  .login-card__label-name {
    color: white; }

.login-card__input {
  display: block;
  border-radius: 30px;
  border: none;
  width: 13vw;
  height: 27px; }

.login-card-left {
  background: #d9e3e8; }
  .login-card-left__link {
    text-decoration: underline;
    font-size: 11px; }

.login-card-right {
  background: black;
  color: white; }
  .login-card-right__button {
    font-size: 13px; }
  .login-card-right__content {
    font-size: 11px;
    font-family: 'Monserrat_medium', sans-serif;
    line-height: 1.15;
    color: #b6b6b6; }
  .login-card-right__text {
    font-size: 11px;
    color: #b6b6b6;
    font-family: 'Monserrat_medium', sans-serif; }

.login-main__button {
  font-size: 13px; }

.login-main-title {
  font-family: 'Monserrat_semibold', sans-serif;
  font-size: 28px;
  border-bottom: 3px solid black; }

.change-password__main {
  background: #d9e3e8; }

.change-password__title {
  font-size: 18px;
  font-family: 'Monserrat_semibold', sans-serif; }

.change-password__label {
  display: block;
  font-size: 14px;
  font-family: 'Monserrat_medium', sans-serif;
  color: black; }
  .change-password__label-right {
    color: white; }

.change-password__input {
  display: block;
  border-radius: 30px;
  border: none;
  width: 14.5vw;
  height: 27px; }

.change-password__button {
  font-size: 13px; }

.footer {
  background: black;
  border-top: 10px solid #0f8cc8;
  font-size: 15px; }
  .footer-menu {
    font-size: 14px;
    font-family: 'Monserrat_medium', sans-serif; }
    .footer-menu__item {
      list-style-type: none;
      color: #b6b6b6; }
      .footer-menu__item:hover {
        color: white; }
      .footer-menu__item_active {
        color: #0f8cc8; }
  .footer-navigation {
    border-top: 2px solid #b6b6b6;
    font-size: 12px;
    font-family: 'Monserrat_medium', sans-serif; }
    .footer-navigation__item {
      color: #b6b6b6;
      cursor: pointer; }
      .footer-navigation__item:hover {
        color: white; }
      .footer-navigation__item_active {
        color: #0f8cc8; }

.navigation-block {
  background-color: white; }

.navigation-link {
  cursor: pointer; }

.navigation-goback {
  border-right: 1px solid #b6b6b6; }

.navigation-icon {
  transform: rotate(180deg);
  fill: #b6b6b6;
  width: 8px;
  height: 8px; }
  .navigation-icon__separate {
    fill: #b6b6b6;
    width: 8px;
    height: 11px;
    margin-bottom: -2px; }
  .navigation-icon__back {
    fill: #b6b6b6;
    width: 8px;
    height: 8px;
    margin-right: 0.5vw; }

.navigation-item {
  color: #b6b6b6;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer; }
  .navigation-item_current {
    color: black;
    cursor: auto; }

.alert {
  color: #ee2020;
  font-size: 13px; }

.categories__popup {
  background-color: white; }
  .categories__popup-list {
    overflow-y: scroll; }
    .categories__popup-list::-webkit-scrollbar {
      width: 5px;
      background-color: #e3e3e3; }
    .categories__popup-list::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
      border-radius: 10px; }
    .categories__popup-list-subcategories {
      border-left: 1px solid #4b4b4b; }
  .categories__popup-title {
    font-family: 'Monserrat_semibold', sans-serif;
    word-wrap: break-word; }
  .categories__popup-item {
    font-size: 13px;
    cursor: pointer; }
    .categories__popup-item:hover {
      color: white;
      background-color: #0f8cc8;
      font-family: 'Monserrat_semibold'; }
      .categories__popup-item:hover .categories__popup-item-icon {
        fill: white; }
    .categories__popup-item-icon {
      fill: #4b4b4b; }

.categories-page__skeleton {
  animation: skeletonLighting 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-iteration-count: 40; }

.categories-page__title {
  font-size: 20px;
  font-family: Monserrat_semibold,sans-serif; }
  .categories-page__title_skeleton {
    width: 250px;
    height: 40px;
    background-color: #e3e3e3; }
  .categories-page__title-content {
    font-family: 'Monserrat', sans-serif;
    font-size: 10px;
    color: #b6b6b6; }

.categories-page__list {
  columns: 3; }
  .categories-page__list-title {
    width: 100%;
    border-bottom: 1px solid #b6b6b6;
    padding-bottom: 12px;
    font-size: 16px;
    font-family: Monserrat_semibold,sans-serif;
    cursor: pointer;
    column-span: all; }
    .categories-page__list-title_skeleton {
      background-color: #e3e3e3;
      height: 30px; }

.categories-page__item {
  cursor: pointer; }
  .categories-page__item-title {
    font-size: 12px; }
    .categories-page__item-title_skeleton {
      background-color: #e3e3e3;
      height: 20px;
      width: 100%;
      display: block; }
  .categories-page__item-content {
    font-size: 10px;
    color: #b6b6b6; }

.categories-item__table {
  text-align: center;
  width: 100%; }
  .categories-item__table-wrapper {
    overflow: unset; }
  .categories-item__table-header {
    background-color: #0f8cc8; }
    .categories-item__table-header .categories-item__table-item {
      outline: 1px solid white;
      padding: 15px;
      border: none;
      position: sticky;
      top: -2px;
      background-color: #0f8cc8; }
  .categories-item__table-row {
    display: flex;
    padding: 25px;
    background-color: white;
    box-shadow: 0 0 20px #00000012;
    border-radius: 10px;
    margin: 15px 0;
    margin-bottom: 25px;
    cursor: pointer;
    gap: 15px;
    align-items: center;
    transition: 0.3s ease; }
    .categories-item__table-row:hover {
      box-shadow: 0 0 20px #0ea6ff40; }
    .categories-item__table-row:hover .categories-item__table-item-btn {
      background: #0f8cc8; }
    .categories-item__table-row:hover .categories-item__table-item-dop {
      border-bottom: 1px solid #0f8cc8;
      color: #0f8cc8; }
    .categories-item__table-row:hover .categories-item__partnumber .categories-item__table-item {
      border-bottom: 1px solid #0f8cc8;
      color: #0f8cc8; }
  .categories-item__table-item {
    font-size: 16px;
    border-bottom: 1px solid transparent;
    padding: 0;
    transition: 0.3s ease; }
    .categories-item__table-item-photo {
      width: 75px;
      height: 75px;
      object-fit: contain; }
    .categories-item__table-item-btn {
      background: #29272a;
      padding: 15px;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      transition: 0.3s ease; }
    .categories-item__table-item-dop {
      border-bottom: 1px solid transparent;
      padding: 0;
      transition: 0.3s ease;
      color: #ccc; }
      .categories-item__table-item-dop::before {
        content: ", "; }
      .categories-item__table-item-dop:nth-child(2)::before {
        content: " ("; }
      .categories-item__table-item-dop:last-child::after {
        content: ")"; }

.categories-item__skeleton {
  animation: skeletonLighting 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-iteration-count: 40;
  width: 104%; }
  .categories-item__skeleton-item {
    background-color: #eeeeee;
    border: none; }
  .categories-item__skeleton-header {
    background-color: #b6b6b6;
    border: none; }

.categories-item__title {
  font-size: 20px;
  font-family: Monserrat_semibold,sans-serif;
  white-space: nowrap; }

.categories-item__filters {
  font-size: 11px;
  display: none; }
  .categories-item__filters::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee; }
  .categories-item__filters::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b6b6b6; }
  .categories-item__filters::-webkit-scrollbar {
    height: 8px;
    border: 1px solid #b6b6b6; }
  .categories-item__filters-title {
    font-size: 12px;
    font-family: Monserrat_semibold,sans-serif;
    border-bottom: 1px solid #b6b6b6;
    white-space: nowrap; }
  .categories-item__filters-item_unactive {
    color: #b6b6b6; }
  .categories-item__filters-item_active {
    background-color: #0f8cc8;
    color: black; }
  .categories-item__filters-list {
    background-color: white; }
    .categories-item__filters-list::-webkit-scrollbar {
      width: 5px;
      background-color: #e3e3e3; }
    .categories-item__filters-list::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
      border-radius: 10px; }
    .categories-item__filters-list-item {
      background-color: white;
      border: 1px solid #b6b6b6;
      border-radius: 10px; }

.categories-item__partnumber {
  color: black;
  display: block;
  max-width: 100%;
  white-space: normal;
  text-align: left;
  line-height: 22px; }

.product-item__photo-item {
  max-width: 12vw;
  height: 12vw;
  background-color: white;
  outline: 1px solid #b6b6b6;
  border-radius: 5px;
  border: none;
  border-image: none; }

.product-item__photo-link {
  font-size: 14px;
  font-family: Roboto_regular,sans-serif; }

.product-item__title {
  font-size: 26px;
  font-family: Monserrat_medium,sans-serif; }

.product-item__icon {
  width: 15px;
  height: 14px;
  fill: #0f8cc8;
  margin-left: 0.5vw; }

.product-item__link {
  font-size: 17px;
  color: #0f8cc8;
  cursor: pointer;
  word-break: break-word; }

.product-item__button {
  width: 12.5vw;
  font-size: 14px; }

.product-item__skeleton {
  background-color: #eeeeee;
  height: 20px; }
  .product-item__skeleton-page {
    animation: skeletonLighting 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    animation-iteration-count: 20; }
  .product-item__skeleton-table {
    margin-left: -2vw;
    width: 107.5%; }
  .product-item__skeleton-button {
    height: 40px;
    margin: auto;
    background-color: #b6b6b6; }
    .product-item__skeleton-button:hover {
      background-color: #b6b6b6; }
    .product-item__skeleton-button:active {
      border: none;
      outline: none; }
  .product-item__skeleton-cart {
    border-bottom: 10px solid #b6b6b6 !important;
    animation: skeletonLighting 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    animation-iteration-count: 20; }

.product-item__info-title {
  color: #b6b6b6;
  font-size: 14px; }

.product-item__manufacturer {
  font-size: 17px; }
  .product-item__manufacturer-image {
    height: 43px;
    object-fit: contain; }

.product-item__partnumber {
  font-size: 17px; }

.product-item__description {
  font-size: 17px; }

.product-table {
  vertical-align: middle;
  text-align: start;
  font-family: Roboto_regular,sans-serif; }
  .product-table__row:nth-child(odd) {
    background-color: #eeeeee; }
  .product-table__row:nth-child(even) {
    background-color: white; }
  .product-table__item {
    font-size: 14px; }
  .product-table__title {
    font-size: 18px;
    font-family: Roboto_regular,sans-serif; }
  .product-table__subtitle {
    font-size: 14px;
    font-family: Roboto_regular,sans-serif;
    cursor: pointer; }
  .product-table__icon {
    transform: rotate(90deg);
    width: 13px;
    height: 16px;
    margin-bottom: -4px;
    fill: black; }
    .product-table__icon_collapsed {
      transform: rotate(270deg); }
  .product-table__checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0; }
    .product-table__checkbox + .product-table__label {
      display: inline-flex;
      align-items: center;
      user-select: none; }
      .product-table__checkbox + .product-table__label::before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #4b4b4b;
        border-radius: 3px;
        margin-right: 1vw;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 50% 50%;
        background-color: white; }
    .product-table__checkbox:checked + .product-table__label::before {
      border-color: #0f8cc8;
      background-color: #0f8cc8;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }
    .product-table__checkbox:not(:checked) + .product-table__label:hover::before {
      border-color: #d9e3e8; }

.product-cart {
  border: 10px solid #b6b6b6;
  border-bottom: none; }
  .product-cart__input {
    border-radius: 10px;
    border: 1px solid #b6b6b6; }
  .product-cart__button {
    width: 7vw;
    height: 46px;
    padding: 0 5px; }
  .product-cart__icon {
    fill: black;
    cursor: pointer; }
  .product-cart__title {
    font-size: 13px;
    font-family: Monserrat_medium, sans-serif; }

.product-similar {
  border: 10px solid #b6b6b6; }
  .product-similar__title {
    border-bottom: 1px solid #b6b6b6;
    font-size: 20px;
    font-family: Monserrat_semibold,sans-serif; }
  .product-similar__item {
    border-bottom: 1px solid #b6b6b6; }
    .product-similar__item-title {
      font-size: 18px;
      font-family: Monserrat_semibold,sans-serif; }
    .product-similar__item-manufacturer {
      font-size: 14px;
      font-family: Monserrat_medium,sans-serif; }
    .product-similar__item-type {
      font-size: 13px; }

.product-popup {
  border-radius: 10px;
  background-color: white;
  z-index: 101; }
  .product-popup__input {
    border: 1px solid #b6b6b6;
    display: block;
    border-radius: 30px;
    width: 13vw;
    height: 27px; }
  .product-popup__button {
    font-size: 14px;
    width: max-content;
    white-space: nowrap;
    padding: 1em 1.6em; }
  .product-popup_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.3;
    z-index: 11; }
  .product-popup::-webkit-scrollbar {
    display: none; }
  .product-popup__error {
    font-size: 12px;
    color: #ee2020; }

.product-orders__table {
  font-size: 16px; }
  .product-orders__table-row .table__item:first-child {
    border-left: none;
    font-size: 16px; }
  .product-orders__table-button {
    font-size: 15px;
    padding: 0 1.6em;
    margin: 27px auto;
    border-width: 1px; }
  .product-orders__table-wrapper {
    overflow-y: auto; }
    .product-orders__table-wrapper::-webkit-scrollbar {
      display: none; }
  .product-orders__table-header {
    font-size: 16px !important;
    font-family: Monserrat_semibold,sans-serif; }
  .product-orders__table-icon {
    width: 10px;
    height: 10px;
    transform: rotate(90deg); }
    .product-orders__table-icon_active {
      transform: rotate(270deg); }
  .product-orders__table-filter {
    border-width: 1px;
    font-size: 15px;
    padding: 0 3em; }

.product-orders__dropdown {
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  background-color: white;
  font-family: Monserrat,sans-serif; }
  .product-orders__dropdown-item {
    border-bottom: 1px solid #eeeeee;
    font-size: 14px; }
    .product-orders__dropdown-item:hover {
      color: #0f8cc8; }
    .product-orders__dropdown-item_active {
      background-color: #0f8cc8;
      color: white; }
      .product-orders__dropdown-item_active:hover {
        color: white; }
  .product-orders__dropdown-list :last-child {
    border-bottom: none; }
  .product-orders__dropdown-icon {
    width: 15px;
    height: 15px;
    fill: white; }

.product-orders__filter-input {
  border: 1px solid #b6b6b6;
  border-radius: 30px; }

.product-orders__filter-close {
  width: 15px;
  height: 15px; }

.product-price__table-item {
  font-size: 14px; }
  .product-price__table-item_error {
    border: 1px solid #ee2020;
    border-radius: 5px; }
    .product-price__table-item_error .product-price__table-button {
      background-color: white; }
    .product-price__table-item_error .product-price__table-quantity {
      background-color: white; }

.product-price__table-header {
  background-color: #eeeeee; }
  .product-price__table-header :first-child {
    width: 13%; }
  .product-price__table-header :nth-child(2) {
    width: 15%; }
  .product-price__table-header :nth-child(3) {
    width: 10%; }
  .product-price__table-header :nth-child(4) {
    width: 8%; }
  .product-price__table-header :nth-child(5) {
    width: 10%; }
  .product-price__table-header :nth-child(6) {
    width: 13%; }
  .product-price__table-header :nth-child(7) {
    width: 12%; }
  .product-price__table-header :nth-child(8) {
    width: 15%; }

.product-price__table-row {
  border-bottom: 1px solid #b6b6b6; }

.product-price__table-button {
  background-color: #eeeeee;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }
  .product-price__table-button_left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.product-price__table-icon {
  width: 12px;
  height: 12px;
  stroke: black;
  cursor: pointer; }
  .product-price__table-icon_disabled {
    stroke: #b6b6b6;
    cursor: default; }

.product-price__table-quantity {
  background-color: #eeeeee;
  font-size: 18px;
  text-align: center; }
  .product-price__table-quantity_disabled {
    color: #b6b6b6;
    padding: 14px 0;
    background-color: #eeeeee;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-family: Roboto_regular,sans-serif; }

.product-price__table-list:hover {
  outline: 1px solid #0f8cc8;
  border-radius: 20px; }

.product-price__table-list_active {
  color: #0f8cc8; }

.product-price__table-error {
  background-color: #f7d6d6;
  color: #ee2020;
  font-size: 10px;
  text-align: center; }

.product-price__table .loader {
  width: 30px;
  height: 30px; }

.product-price__cart {
  border: 1px solid #b6b6b6;
  border-radius: 5px; }
  .product-price__cart-icon {
    fill: white;
    width: 15px;
    height: 15px; }
    .product-price__cart-icon_disabled {
      fill: #d2d2d2; }
  .product-price__cart-total {
    font-size: 14px;
    font-family: 'Roboto_bold', sans-serif; }
  .product-price__cart-price {
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: clip;
    font-family: Monserrat_semibold,sans-serif; }
  .product-price__cart-quantity {
    font-size: 14px;
    font-family: 'Roboto_bold', sans-serif; }
  .product-price__cart-button {
    font-size: 13px;
    white-space: nowrap;
    padding: 17.5px 28.8px; }
  .product-price__cart-title {
    border-bottom: 1px solid #b6b6b6;
    font-size: 14px;
    font-family: Roboto_regular, sans-serif; }
  .product-price__cart-product {
    font-size: 14px;
    font-family: Roboto_regular, sans-serif; }

.product-price__empty {
  font-size: 16px;
  font-family: Monserrat_semibold,sans-serif; }
  .product-price__empty-link {
    color: #0f8cc8;
    cursor: pointer; }

.pagination {
  color: #0f8cc8; }
  .pagination__number {
    cursor: pointer; }
    .pagination__number_active {
      color: white;
      background-color: #0f8cc8; }
    .pagination__number_last::before {
      content: "...";
      align-self: end;
      margin-right: 1vw;
      color: #0f8cc8; }
    .pagination__number_first::after {
      content: "...";
      align-self: end;
      margin-right: 1vw;
      color: #0f8cc8; }
  .pagination__icon {
    transform: rotate(0deg); }
  .pagination__item {
    cursor: pointer; }
  .pagination__select {
    font-size: 11px;
    color: black; }
    .pagination__select-item {
      background-color: white;
      border: 1px solid #b6b6b6;
      font-size: 13px;
      cursor: pointer; }

.search-options__list {
  background-color: white;
  border: 1px solid #b6b6b6;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: none; }
  .search-options__list_active {
    display: flex; }
  .search-options__list::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    z-index: -1; }
  .search-options__list::-webkit-scrollbar-thumb {
    background-color: #b6b6b6;
    border-radius: 10px; }
  .search-options__list::-webkit-scrollbar-track-piece:end {
    margin-bottom: 20px;
    background-color: transparent; }

.search-options__item {
  cursor: pointer; }
  .search-options__item:hover {
    color: #0f8cc8;
    background: #f3f4f6; }

.search-options__key {
  color: #b6b6b6;
  font-size: 13px;
  float: right; }

.search-options__image {
  width: 32px;
  height: 32px;
  border-radius: 5px; }

.table {
  table-layout: fixed;
  border-collapse: collapse;
  text-align: center;
  font-size: 13px;
  font-family: 'Monserrat_medium', sans-serif; }
  .table__icon-save {
    width: 20px;
    height: 20px;
    fill: #0f8cc8;
    cursor: pointer;
    margin-top: 12px; }
  .table__icon-close {
    top: 20px;
    right: 25px;
    width: 22px;
    fill: black;
    cursor: pointer;
    position: absolute; }
  .table__button {
    font-size: 12px;
    padding: 0 1.6em;
    margin: 10px auto; }
  .table__item {
    border: 1px solid #b6b6b6;
    height: 38px;
    vertical-align: middle;
    padding-top: 5px; }
    .table__item_active {
      border-bottom: 2px solid #0f8cc8; }
    .table__item-image {
      width: 40px;
      height: 40px; }
    .table__item-product:not(:last-child) {
      border-bottom: 1px solid #b6b6b6; }
  .table-header {
    background: #eeeeee;
    height: 40px; }
    .table-header__item {
      vertical-align: middle; }
      .table-header__item_sticky {
        vertical-align: middle;
        position: sticky;
        top: 0px;
        background: #eeeeee;
        height: 55px;
        font-size: 20px;
        z-index: 110; }
  .table-row:last-child .table__item {
    vertical-align: middle;
    height: 35px; }
  .table-row .table__item:first-child {
    border-left: none;
    font-size: 14px; }
  .table-row .table__item:last-child {
    border-right: none; }

.loader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  -ms-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  background: linear-gradient(90deg, #74bde0, #0f8cc8); }
  .loader__inside {
    width: 100%;
    height: 50%;
    position: absolute;
    margin-top: 50%; }
    .loader__inside:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      margin-top: -50%;
      background: linear-gradient(90deg, #74bde0, #d2d2d2); }
    .loader__inside:after {
      content: '';
      width: 80%;
      height: 160%;
      position: absolute;
      margin-top: -40%;
      margin-left: 10%;
      background: white;
      border-radius: 50%; }
  .loader__text {
    font-size: 14px; }

.user-agreement__title-h1 {
  font-size: 27px;
  font-family: "Monserrat_bold", sans-serif;
  color: black;
  text-align: center; }

.user-agreement__text {
  font-size: 14px;
  font-family: 'Roboto_regular', sans-serif;
  line-height: 1.5;
  color: black; }

@keyframes carouselContentAnimation {
  0% {
    transform: translateY(150px);
    opacity: 0; }
  50% {
    transform: translateY(70px);
    opacity: 0.7; }
  100% {
    opacity: 1; } }

@keyframes carouselListAnimation {
  0% {
    transform: translateY(260px);
    opacity: 0; }
  60% {
    transform: translateY(100px);
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sliderButtonAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  70% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@keyframes cardLeftAnimation {
  0% {
    transform: translateX(-350px);
    opacity: 0; }
  50% {
    transform: translateX(-170px);
    opacity: 0.7; }
  100% {
    opacity: 1; } }

@keyframes cardRightAnimation {
  0% {
    transform: translateX(350px);
    opacity: 0; }
  50% {
    transform: translateX(170px);
    opacity: 0.7; }
  100% {
    opacity: 1; } }

@keyframes skeletonLighting {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 1; } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@media (min-width: 1300px) and (max-width: 1500px) {
  .product-orders__table-filter {
    padding: 0 3vw; } }

@media (min-width: 900px) and (max-width: 1300px) {
  .brand-grid__img {
    width: 13vw; }
  .carousel-content {
    position: relative; }
  .menu-top {
    width: auto; }
  .menu-middle {
    width: auto; }
  .menu-bottom {
    position: relative; }
  .product-item__info-title {
    font-size: 12px; }
  .product-item__partnumber {
    font-size: 12px; }
  .product-item__description {
    font-size: 12px; }
  .product-item__manufacturer {
    font-size: 12px; }
    .product-item__manufacturer-image {
      width: 4vw;
      height: 30px; }
  .product-item__photo-link {
    max-width: 30vw; }
  .product-table__subtitle {
    margin: 0 0 10px auto; }
    .product-table__subtitle_bottom {
      margin: 10px 0 50px auto; }
  .product-price__cart {
    max-width: 20vw; }
    .product-price__cart-button {
      padding: 0 2vw;
      font-size: 0.9vw;
      white-space: nowrap;
      margin: 15px 0;
      width: 100%; }
    .product-price__cart-total {
      padding: 6px 0; }
    .product-price__cart-quantity {
      margin: 10px 0 15px; }
  .product-price__table-item {
    white-space: nowrap; }
  .product-price__table-quantity_disabled {
    font-size: 10px; }
  .product-cart__button {
    font-size: 12px; }
  .product-orders__table-filter {
    padding: 0 2.5vw; }
  .login-card__label-name {
    white-space: nowrap; }
  .change-password__main {
    min-width: max-content; }
  .change-password__input {
    min-width: 150px; }
  .main-card {
    overflow: hidden; }
  .navigation-block {
    margin-top: 0; } }

@media (max-width: 900px) {
  .brand-grid__img {
    width: 18vw; }
  .main-card {
    overflow-x: hidden; }
    .main-card-left__content {
      width: 41vw; }
    .main-card-right__content {
      width: 41vw; }
  .footer-menu {
    grid-template-columns: repeat(2, 30.2vw); }
  .carousel-content {
    position: relative; }
  .carousel-list__item-line {
    width: 26.7vw; }
  .menu-top {
    width: auto; }
  .menu-middle {
    width: auto; }
    .menu-middle__logo {
      width: 25vw;
      max-width: none; }
    .menu-middle__search {
      margin: 37px 4vw 39px 5.4vw; }
      .menu-middle__search-input {
        width: 43vw;
        min-width: 380px; }
  .menu-bottom {
    position: relative; }
  .search-options__list {
    left: 44.6vw;
    width: 43vw;
    min-width: 380px; }
  .navigation-block {
    margin-top: 10px;
    padding: 10px 11vw; }
  .navigation-icon__separate {
    width: 7px;
    height: 9px; }
  .navigation-icon__back {
    width: 9px;
    height: 9px; }
  .navigation-item {
    font-size: 13px; }
  .navigation-goback {
    line-height: 0.8; }
  .product-item__title {
    font-size: 18px; }
  .product-item__photo {
    width: 25vw; }
    .product-item__photo-item {
      max-width: 23vw;
      height: 23vw; }
    .product-item__photo-link {
      font-size: 10px;
      max-width: 30vw; }
  .product-item__info {
    max-width: 19vw;
    margin-left: 2vw; }
    .product-item__info-title {
      font-size: 11px; }
  .product-item__partnumber {
    font-size: 11px; }
  .product-item__description {
    font-size: 11px; }
  .product-item__manufacturer {
    font-size: 11px; }
    .product-item__manufacturer-image {
      width: 5vw;
      height: 30px; }
  .product-table__title {
    font-size: 14px; }
  .product-table__subtitle {
    font-size: 10px; }
  .product-table__item {
    font-size: 10px; }
  .product-table__block {
    width: 32.6%;
    float: right; }
  .product-table__icon {
    width: 12px;
    height: 10px; }
  .product-price__cart {
    max-width: 30vw;
    padding: 12px 1vw; }
    .product-price__cart-button {
      font-size: 10px;
      padding: 10px;
      height: max-content; }
    .product-price__cart-total {
      font-size: 12px;
      padding: 0; }
    .product-price__cart-quantity {
      font-size: 12px;
      margin: 10px 0 15px; }
  .product-price__table-item {
    font-size: 10px; }
  .product-price__table-button {
    font-size: 10px;
    padding: 10px 0.5vw; }
  .product-price__table-quantity {
    font-size: 10px; }
  .product-cart__button {
    font-size: 10px; }
  .login-main__button {
    font-size: 12px;
    padding: 12px calc(1.6 * 12px); }
  .login-card-right__button {
    font-size: 12px;
    padding: 12px calc(1.6 * 12px); }
  .login-card__input {
    width: 18vw;
    min-width: 160px; }
  .change-password__main {
    min-width: max-content; }
  .change-password__input {
    min-width: 150px; }
  .categories-page {
    padding: 35px 11vw; }
    .categories-page__list {
      columns: 2; }
  .categories-item {
    padding: 10px 11vw; } }

@media (max-width: 600px) {
  .menu-middle__search {
    margin: 37px 2vw 39px 13.4vw; }
    .menu-middle__search-input {
      text-overflow: ellipsis;
      overflow-x: clip;
      padding: 0 25px 0 15px;
      min-width: 420px; }
      .menu-middle__search-input::placeholder {
        font-size: 10px; }
  .menu-middle__logo {
    width: 35vw; }
  .menu-middle__login-text {
    font-size: 12px;
    padding-left: 1.8vw; }
  .search-options__list {
    left: 62.5vw;
    min-width: 420px; }
  .main-title__text {
    font-size: 18px; }
  .footer-menu {
    grid-template-columns: repeat(2, 50.2vw); }
  .footer-navigation {
    font-size: 8px; }
  .manufacturer__title {
    font-size: 17px; }
  .destributers__title {
    font-size: 17px; }
  .main-card-left__content {
    font-size: 14px; }
  .main-card-right__content {
    font-size: 14px; }
  .carousel-list__item-text {
    font-size: 14px; }
  .carousel-list__item-number {
    font-size: 16px; }
  .carousel-content__title {
    font-size: 45px; }
  .carousel-content__text {
    font-size: 18px; }
  .navigation-icon__separate {
    width: 3px;
    height: 5px; }
  .navigation-icon__back {
    width: 4px;
    height: 6px; }
  .navigation-item {
    font-size: 8px; }
  .navigation-goback {
    line-height: 0.6; }
  .product-item {
    padding: 0 0 0 11vw; }
    .product-item__title {
      font-size: 14px; }
    .product-item__photo {
      width: 25vw; }
      .product-item__photo-item {
        max-width: 23vw;
        height: 23vw; }
      .product-item__photo-link {
        font-size: 8px; }
    .product-item__info {
      max-width: 40vw;
      margin-left: 6vw; }
      .product-item__info-title {
        font-size: 8px; }
    .product-item__partnumber {
      font-size: 8px; }
    .product-item__description {
      font-size: 8px; }
    .product-item__manufacturer {
      font-size: 8px; }
      .product-item__manufacturer-image {
        width: 9vw;
        height: 30px; }
  .product-table__title {
    font-size: 12px; }
  .product-table__subtitle {
    font-size: 6px; }
  .product-table__item {
    font-size: 8px; }
  .product-table__block {
    width: 41.6%;
    margin: 0 calc(100vw/9) 0 0; }
  .product-table__icon {
    width: 9px;
    height: 7px; }
  .product-price__cart {
    min-width: 100px; }
    .product-price__cart-button {
      font-size: 8px;
      padding: 10px;
      height: max-content; }
    .product-price__cart-total {
      font-size: 10px;
      padding: 0; }
    .product-price__cart-quantity {
      font-size: 8px;
      margin: 0 0 5px; }
  .product-price__table-item {
    font-size: 8px; }
    .product-price__table-item_height {
      width: 30vw; }
  .product-price__table-button {
    font-size: 8px;
    padding: 10px 18px; }
  .product-price__table-quantity {
    font-size: 8px; }
  .product-price__block {
    margin-top: 80px; }
  .product-cart__button {
    font-size: 10px; }
  .brand-grid__img {
    width: 28vw; }
  .login-main {
    grid-template-columns: repeat(10, 9%); }
    .login-main__button {
      font-size: 10px;
      padding: 10px 16px; }
  .login-card-right__button {
    font-size: 12px;
    padding: 12px calc(1.6 * 12px); }
  .login-card__input {
    width: 30vw; } }

@media (max-width: 450px) {
  .menu-middle__search {
    margin: 37px 2vw 39px 13.4vw;
    left: calc(100vw / 4); }
  .search-options__list {
    left: calc(62.5vw + 100vw / 4);
    min-width: 420px; }
  .categories-page__list {
    columns: 1; } }
